<template>
  <div class="d-flex flex-column flex-root" style="height: 100%">
    <div
        class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
        style="background-repeat: no-repeat;
              background-size: cover;
              "
    >
      <!-- begin:: Content -->
      <h1
          class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style="font-size: 150px; margin: 0 auto;"
      >
        404
      </h1>
      <p class="font-size-h3 font-weight-light" style="margin: 0 auto">
        OOPS! Something went wrong here
      </p>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Error-1",
  mounted() {
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/anhbia1.png";
    }
  }
};
</script>
